.fade-enter-active[data-v-c13b0d60], .fade-leave-active[data-v-c13b0d60] {
  transition: .5s opacity .1s;
}
.fade-enter[data-v-c13b0d60], .fade-leave-to[data-v-c13b0d60] {
  transition-delay: 0s;
  opacity: 0;
}
.fade-leave-active[data-v-c13b0d60] {
  top: 0;
  position: absolute;
  width: calc(100% - 22px);
}
.shuffle-move[data-v-c13b0d60] {
  transition: all 600ms ease-in-out 50ms;
}
.shuffle-enter-active[data-v-c13b0d60] {
  transition: all 300ms ease-out;
}
.shuffle-leave-active[data-v-c13b0d60] {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}
.shuffle-enter[data-v-c13b0d60],
.shuffle-leave-to[data-v-c13b0d60] {
  opacity: 0;
}
.shuffle-enter[data-v-c13b0d60] {
  transform: scale(0.9);
}
